import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { CardActionArea } from '@mui/material';

export default function FeaturesCard() {
  return (
    <Card sx={{ maxWidth: 355 }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          📝Features
          </Typography>
          
          <List>
            <ListItem>
              <Typography variant="body2" color="text.secondary">
                Easily keep track of <strong>dozens</strong> or even <strong>hundreds</strong> of birthdays
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
                Receive a text when it is someone's birthday
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
                Customize a message that will be sent to you which you can copy and paste with the person's name included
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
                Always remember your friends and family's birthdays
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
                Receive a notification from anywhere
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
