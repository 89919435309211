import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import { CardActionArea } from '@mui/material';

export default function InstructionsCard() {
  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
          Setup Instructions
          </Typography>

          <List>
            <ListItem>
              <Typography variant="body1" color="text.secondary">
                Use the Google Chrome web browser for the following steps.
              </Typography>
            </ListItem>

            <Divider />
            <ListItem>
              <Typography variant="body1" color="text.secondary">
                1. Navigate to <a rel="noreferrer noopener" target="_blank" href="https://lcr.churchofjesuschrist.org/records/member-list?lang=eng">LDS.org -> Membership -> Member List</a>.
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body1" color="text.secondary">
              2. Scroll to the bottom of the page and note the total number of members in your ward.
              (This is to ensure that all members are included in the export.)
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <List>
                <Typography variant="body1" color="text.secondary">
                3. Copy the entire webpage to your clipboard.
                </Typography>
                <ListItem>
                  <Typography variant="body2" color="text.secondary">
                    Shortcuts
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2" color="text.secondary">
                  Windows: <kbd>Ctrl</kbd> + <kbd>A</kbd>, <kbd>Ctrl</kbd> + <kbd>C</kbd>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2" color="text.secondary">
                  Mac: <kbd>⌘ Command</kbd> + <kbd>A</kbd>, <kbd>⌘ Command</kbd> + <kbd>C</kbd>
                  </Typography>
                </ListItem>
              </List>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body1" color="text.secondary">
              4. Navigate to your dashboard and select <strong>Create List</strong>.
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1" color="text.secondary">
              5. Follow the instructions shown under Add List.
              </Typography>
            </ListItem>
          </List>
          
          {/* <List>
            <ListItem>
              <Typography variant="body2" color="text.secondary">
              1. Download spreadsheet <a href=".\Birthday-Hero-List-Template.xlsx" download="Birthday-Hero-List-Template.xlsx">.xlsx (excel)</a> or <a href=".\Birthday-Hero-List-Template.csv" download="Birthday-Hero-List-Template.csv">.csv (universal)</a>
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              2. Enter information
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              3. Email spreadsheet to <a href="mailto: helloforesightcode@gmail.com" target="1" >helloforesightcode@gmail.com</a>
              </Typography>
            </ListItem>
          </List> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
