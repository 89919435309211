import * as React from 'react';

import Typography from '@mui/material/Typography';

const Account = ({ user }) => {

    return (
        <div className="account">
            <Typography variant="h2" color="text.primary" sx={{ ml:2, mt:2 }}>
                Account
            </Typography>
            <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h6" textAlign="left">{user.displayName}</Typography>
            <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h6" textAlign="left">{user.email}</Typography>
            {/* <Button sx={{ ml: 2 }} variant="contained">Send Password Reset Email</Button> */}
        </div>
    )
}

export default Account;