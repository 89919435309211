import * as React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import { CircularProgress } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import SortByMenu from './SortByMenu';

import moment from 'moment';

export default function Row ({ list, deleteList }) {
    const [open, setOpen] = React.useState(false);

    function formatTimestamp (timestamp) {
      return moment(timestamp).utc().format('MM/DD/YYYY');
    }
  
    function convertSecondsToTime (seconds) {
      const date = new Date(null);
      date.setSeconds(seconds);
      return date;
    }
  
    function formatNumber(number){
      return number.substr(0,3)+"-"+number.substr(3,3)+"-"+number.substr(6,4);
    }

    const handleDelete = (list) => {
        deleteList(list.id);
    }
    
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={"inner"}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {list.name}
          </TableCell>
          <TableCell align="right">{list.people.length}</TableCell>
          <TableCell align="right">{formatTimestamp(convertSecondsToTime(list.dateCreated.seconds))}</TableCell>
          <TableCell align="right"><Button variant="text" onClick={ async() => { await handleDelete(list); }}><DeleteOutlineIcon/></Button></TableCell>
        </TableRow>

        {/* Create a sort button with a Material UI Menu */}

        <TableRow key={list.name}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Container maxWidth="md">
                  <SortByMenu/>
                </Container>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow  key={"inner-header"}>
                      <TableCell><h4>Last</h4></TableCell>
                      <TableCell align="left"><h4>First</h4></TableCell>
                      {/* <TableCell align="right">Age</TableCell> */}
                      <TableCell align="right"><h4>Phone Number</h4></TableCell>
                      <TableCell align="right"><h4>Birthday (MDT)</h4></TableCell>
                      {/* <TableCell align="right">Delete List</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.people.map((person) => (
                      <TableRow key={person.last_name + " " + person.first_name}>
                        <TableCell component="th" scope="row">{person.last_name}</TableCell>
                        <TableCell align="left">{person.first_name}</TableCell>
                        {/* <TableCell align="right">{person.age}</TableCell> */}
                        <TableCell align="right">{person.phone ? formatNumber((person.phone + "")) : "n/a"}</TableCell>
                        <TableCell align="right">{formatTimestamp(convertSecondsToTime(person.alert_date.seconds))}</TableCell>
                        {/* <TableCell align="right"><Button variant="text"><DeleteOutlineIcon/></Button></TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }