import * as React from 'react';

import '../../App.css';
import ValuePropCard from '../Cards/ValuePropCard';
import FeaturesCard from '../Cards/FeaturesCard';
import HowItWorksCard from '../Cards/HowItWorksCard';
import ContactCard from '../Cards/ContactCard';
import Container from '@mui/material/Container';

import Grid from '@mui/material/Unstable_Grid2';

const Home = () => {
  return (
    <div className="home">
      <Container maxWidth="md">
        <Grid container spacing={2} sx={{ m: 2}}>
          <Grid xs>
          </Grid>
          <Grid xs={12} md={6}>
          <ValuePropCard />
          </Grid>
          <Grid xs>
          </Grid>
          <Grid xs={12} md={6}>
          <FeaturesCard />
          </Grid>
          <Grid xs={12} md={6}>
          <HowItWorksCard />
          </Grid>
          <Grid xs={12} md={6}>
          <ContactCard />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Home;