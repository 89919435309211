import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCI5juh06oen6g2hqFK9Xc7seLxhDtzkx8",
    authDomain: "never-forget-another-birthday.firebaseapp.com",
    projectId: "never-forget-another-birthday",
    storageBucket: "never-forget-another-birthday.appspot.com",
    messagingSenderId: "653769057452",
    appId: "1:653769057452:web:fb1587638406e13a076b09",
    measurementId: "G-BSNY7JMVNS"
  };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

export const auth = firebase.auth();
export const db = getFirestore(app);

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;