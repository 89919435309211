import * as React from 'react';
import AppContext from '../../services/AppContext';
import firebase from '../../services/firebase';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import { useState, useEffect } from 'react';

import { db, signInWithGoogle } from '../../services/firebase';
import {collection, addDoc, getDocs, onSnapshot, query, where} from 'firebase/firestore';

function PricingContent() {
  const [user, setUser] = useState(AppContext.user);

  const [products, setProducts] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [stripeLink, setStripeLink] = useState("");
  
  function userIsSubscribed(prodId) {
    var isEqual = false;
    activeSubscriptions.forEach((sub) => {
      if (sub === prodId) {
        isEqual = true;
      }
    })
    return isEqual;
  }

  async function getCustomerSubscriptions(customerDocId) {
    const data = await getDocs(collection(db, "stripe_customers_v1", customerDocId, "subscriptions"));
    const subs = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
    var activeSubs = [];
    subs.forEach((sub) => {
      if (sub.status === "active") {
        var priceId = sub.price.path.split("/");
        priceId = priceId[priceId.length - 1];
        activeSubs.push(priceId);
      }
    })
    setActiveSubscriptions(activeSubs);
  }

  async function getCustomer(customerEmail) {
    const customersRef = collection(db, "stripe_customers_v1");
    const q = query(customersRef, where("email", "==", customerEmail));
    const querySnapshot = await getDocs(q);

    const subIds = [];
    querySnapshot.forEach((doc) => {
      subIds.push(doc.id);
      setStripeLink(doc.data().stripeLink);
    });
    if (subIds.length > 0) {
      getCustomerSubscriptions(subIds[0]);
    }
  }

  async function getProducts() {
    const data = await getDocs(collection(db, "stripe_products_v1"));
    var tempProducts = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
    // Loop through each product
    tempProducts.forEach(async (product) => {
      // Get the prices for each product
      const prices = await getDocs(collection(db, "stripe_products_v1", product.id, "prices"));
      // Add prices list to product
      product.prices = [];
      // Loop through each price
      prices.forEach((price) => {
        // Append each price to the product
        product.prices.push({...price.data(), id: price.id});
      })
      setProducts(tempProducts);
    })
  }

  async function manageAccount() {
    window.location.assign(stripeLink);
  }

  async function checkout ( price_uid ) {
    // console.log("Price UID: " + price_uid);
    const docRef = collection(db, "stripe_customers_v1", user.uid, "checkout_sessions");
    const newCheckoutSession = {
      price: price_uid,
      allow_promotion_codes: true,
      success_url: window.location.origin + "/dashboard",
      cancel_url: window.location.origin,
    };

    const newDoc = await addDoc(docRef, newCheckoutSession);
    // console.log(newDoc);

    // console.log("Done adding checkout session");

    // Wait for the CheckoutSession to get attached by the extension

    onSnapshot(newDoc, (doc) => {
      const data = doc.data();
      const { error, url } = data;
      if (error) {
        alert('An error occured:' + error.message);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(newUser => {
      AppContext.user = newUser;
      setUser(newUser);
    });
    getProducts();
    if (user) {
      getCustomer(user.email);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Always know when it is a ward members birthday
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Send them a custom birthday text
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Become the birthday hero in your ward
        </Typography>
      </Container>
      {/* End hero unit */}
      
      <Container component="div" maxWidth="xs">
        {products.map((product) => (
          <Grid key={product.id} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {product.prices !== undefined ? <div>
            {product.prices.map((tier) => (
              <Grid item key={tier.id} xs={6}>
                <Card sx={{ mb: 3, }}>
                  <CardHeader
                  title={product.name}
                  subheader={tier.interval}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.interval === 'year' ? <StarIcon /> : null}
                  subheaderTypographyProps={{ align: 'center', }}
                  sx={{
                      backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                  }}
                  />
                  <CardContent>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h3" color="text.primary">
                        ${tier.unit_amount / 100}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                        {tier.interval === 'month' ? '/mo' : '/yr'}
                        </Typography>
                    </Box>
                    <ul>
                      <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                      >
                          {product.stripe_metadata_feature1PromoText}
                      </Typography>
                      <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                      >
                          {product.stripe_metadata_feature2PromoText}
                      </Typography>
                    </ul>
                  </CardContent>

                  {user ? 
                    <Container sx={{ mb: 2, }}>
                      {userIsSubscribed(tier.id) === true ?
                        <Button fullWidth variant='contained' disabled>Current Plan</Button>
                      :
                        <div>
                          {activeSubscriptions.length > 0 ?
                            <div>
                              <Button onClick={ async () => { await manageAccount();} } fullWidth variant='contained'>Manage Subscription</Button>
                            </div>
                          :
                            <Button onClick={async () => {await checkout(tier.id);} } fullWidth variant='contained'>Subscribe</Button>
                          }
                        </div>
                      }
                    </Container>
                  : <Container sx={{ mb: 2, }}>
                      <Button fullWidth variant='contained' onClick={signInWithGoogle}>Login to purchase</Button>
                    </Container>
                  }
                </Card>
              </Grid>
            ))}
          </div> : <div></div>}
          </Grid>
        ))}
      </Container>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}