import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import InstructionsCard from '../Cards/InstructionsCard';

const Setup = () => {
    return (
      <div className="setup">
      <Grid container spacing={2} sx={{ m: 2}}>
        <Grid xs={12} md={6}>
        <InstructionsCard />
        </Grid>
      </Grid>
      </div>
    )
}

export default Setup;