import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { CardActionArea } from '@mui/material';

export default function ContactCard() {
    var d = new Date();

  return (
    <Card sx={{ maxWidth: 355 }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          📧Contact
          </Typography>
          
          <List>
            <ListItem>
              <Typography variant="body2" color="text.secondary">
                <a href="mailto: helloforesightcode@gmail.com" target="1" >helloforesightcode@gmail.com</a>
                <small>&copy; Copyright {d.getFullYear()}, Foresight Code</small>
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
