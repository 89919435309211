
// MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography } from '@mui/material';


const PreviewMemberListTable = ({table, deletePersonFromList}) => {

  function formatNumber(number){
    if (typeof number === 'undefined') {
      return "";
    }
    
    // Remove all non-digit characters
    number = number.replace(/\D/g,'');
    // Check that the number has 10 digits
    if (number.length > 10) {
      number = number.slice(1)
    }
    if (number.length === 10) {
      return number.substr(0,3)+"-"+number.substr(3,3)+"-"+number.substr(6,4);
    }
    if (number.length === 0) {
      return "";
    }
    if (number === "unknown") {
      return "";
    }
    return number;
  }

  return (
    <div>
    <Divider />
    <TableContainer component={Paper} sx={{ mt:2, ml:1, mr:1, mb:2}}>
      <Typography variant="h6" color="text.primary" sx={{ mt:2, ml:1}}>
        Total Rows: {table.length}
      </Typography>

      <Table sx={{ mr:1, ml:1, mb:2 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name (Last, First)</TableCell>
          {/* <TableCell align="right">Gender</TableCell>
          <TableCell align="right">Age</TableCell> */}
          <TableCell align="right">Birth Date</TableCell>
          <TableCell align="right">Phone Number</TableCell>
          <TableCell align="right">Delete</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {table.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            {/* <TableCell align="right">{row.gender.toUpperCase()}</TableCell> */}
            {/* <TableCell align="right">{row.age}</TableCell> */}
            <TableCell align="right">{row.birth_date}</TableCell>
            <TableCell align="right">{formatNumber(row.phone_number)}</TableCell>
            {/* <TableCell align="right">{row.e_mail}</TableCell> */}
            <TableCell align="right"><Button variant="text" onClick={ async () => { await deletePersonFromList({"name": row.name, "birth_date": row.birth_date, "phone_number": row.phone_number});}}><DeleteOutlineIcon/></Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
      </Table>
    </TableContainer>

  </div>
  )
}

export default PreviewMemberListTable;