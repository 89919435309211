/* eslint-disable no-unused-vars */
import { useState } from 'react';

// MUI
// import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { Stack } from '@mui/system';

const Login = () => {

    // vars
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div className="login">
            <Container>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                    <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    >
                    Login - Under Construction
                    </Typography>
                    <Divider/>
                    <Typography variant="h5" align="center" color="text.secondary" component="p">
                    Google
                    </Typography>
                    <Divider />
                    <Typography variant="h5" align="center" color="text.secondary" component="p">
                    Email & Password
                    </Typography>

                    <Stack
                        component="form"
                        sx={{
                            // width: '75ch',
                            mt:2, ml:1, mr:1, mb: 2
                          }}
                          spacing={4}
                          noValidate
                    >
                        <TextField
                            required
                            id="email"
                            label="Email"
                            defaultValue=""
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <TextField
                            required
                            id="password"
                            label="Password"
                            defaultValue=""
                            variant="outlined"
                            onChange={(e) => setPassword(e.target.value)}
                        />

                    <Typography variant="p" align="center" color="text.secondary" component="p">
                        Forgot password
                    </Typography>
                    </Stack>
                </Container>
            </Container>
        </div>
    )
}

export default Login;