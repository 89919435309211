import * as React from 'react';
// import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';

const UserServicingDashboard = ({ account }) => {
    // const [user, setUser] = React.useState(null);

    return (
        <div>
            <h1>User Servicing Dashboard</h1>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField id="input-with-sx" label="With sx" variant="standard" />
                </Box>
            </Box>
        </div>
    )
}

export default UserServicingDashboard;