import { useState, Fragment } from 'react';

import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// import RefreshIcon from '@mui/icons-material/Refresh';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MuiTelInput } from 'mui-tel-input'; // matchIsValidTel

import moment from 'moment';

import ListsTable from '../Table/ListsTable';

import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { CardContent, Typography, Card } from '@mui/material';
import PreviewMemberListTable from '../Table/PreviewMemberListTable';


const Dashboard = ({ 
                    lists, 
                    products, 
                    activeSubscription, 
                    recipients,
                    addRecipient,
                    deleteRecipient,
                    addList,
                    deleteList }) => {

  const [listName, setListName] = useState('');
  const [memberList, setMemberList] = useState('');
  const [parsedMemberList, setParsedMemberList] = useState([
      // {"name": "1 1", "birth_date": "11/11/1999", "phone_number": "9999999999"},
      // {"name": "2 2", "birth_date": "11/11/1998", "phone_number": "8888888888"},
      // {"name": "3 3", "birth_date": "11/11/1997", "phone_number": "7777777777"},
      // {"name": "4 4", "birth_date": "11/11/1996", "phone_number": "6666666666"}
  ]);

  const [recipientName, setRecipientName] = useState('');
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [recipientMessage, setRecipientMessage] = useState('');

  const [addListDrawerState, setState] = useState({ bottom: false, });

  const [addRecipientDrawerState, setPhoneNumberDrawerState] = useState({ bottom: false, })

  const [newListType, setNewListType] = useState("import_member_list");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthday, setBirthday] = useState(null);

  class Person {
    constructor(name, gender, age, birth_date, address_line_1, address_line_2, address_line_3, phone_number, email) {
      this.name = name;
      this.gender = gender;
      this.age = age;
      this.birth_date = birth_date;
      this.address_line_1 = address_line_1;
      this.address_line_2 = address_line_2;
      this.address_line_3 = address_line_3;
      this.phone_number = phone_number;
      this.email = email;
    }
  }

  // Mutating Functions ***************************************************************************

  const handlePhoneChange = (newPhone) => {
    // matchIsValidTel(newPhone)
    setPhoneNumber(newPhone)
  }

  function addPersonToList() {
    setPhoneNumber(phoneNumber.replace(/\D/g,''));

    // Remove first number if there are more than 10
    if (phoneNumber.length > 10) {
      setPhoneNumber(phoneNumber.slice(1));
    }

    const newPerson = {
      birth_date: moment(birthday).format('MM-DD-YYYY'),
      name: lastName + " " + firstName,
      phone_number: phoneNumber
    };
    var newPersonList = parsedMemberList;
    newPersonList.push(newPerson);
    newPersonList = convertList(newPersonList);
    setParsedMemberList(newPersonList);

    // Clear the fields
    setPhoneNumber("");
    setBirthday(null);
    setFirstName("");
    setLastName("");
  }

  function deletePersonFromList(person) {
    setParsedMemberList(parsedMemberList.filter((p) => (p.name !== person.name && p.birth_date !== person.birth_date && p.phone_number !== person.phone_number)));
  }

  function addListDashboard() {
    var convertedList = convertList(parsedMemberList);
    // console.log("Testing addListDashboard: ", convertedList)
    try {
      if (convertedList.length > 0 && listName.length > 0) {
        const now = new Date();
        addList({
          "dateCreated" : now,
          "name" : listName,
          "people" : convertedList,
        })
        setState(false);
      } else {
        alert("Must include a list name and the list must contain at least one entry.");
        setState(true);
      }
    } catch(e) {
      console.error("Error adding document: ", e);
    }
  }

  function handleAddRecipient() {
    try {
      if (recipientPhoneNumber.length !== 10) {
        alert("Invalid phone number. Must be exactly 10 digits.");
        return;
      }
      if (!is_numeric(recipientPhoneNumber)) {
        alert("Invalid phone number. Only numbers allowed");
        return;
      }
      if (recipientMessage.length > 300) {
        alert("Invalid Message. Must be 300 characters or less.");
        return;
      }
      if (recipientName.length === 0) {
        alert("Invalid Name. Must include a name.");
        return;
      }
      const recipient = {
        "name" : recipientName,
        "phoneNumber" : recipientPhoneNumber,
        "message" : recipientMessage
      }
      addRecipient(recipient)
      setPhoneNumberDrawerState(false);
    } catch (e) {
      console.log("Error adding recipient");
    }
  }

  // Helper Functions *****************************************************************************

  function formatPhoneNumber(number) {
    return number.substr(0,3)+"-"+number.substr(3,3)+"-"+number.substr(6,4);
  }

  function hasActiveSubscription() {
    if (getMaxLists() !== 0 && getMaxPhoneNumbers() !== 0) {
      return true;
    }
    return false;
  }

  function getMaxLists() {
    if (activeSubscription.product && products) {
      for (let j = 0; j < products.length; j++) {
        if (activeSubscription.product.path.includes(products[j].id)) {
          return products[j].stripe_metadata_maxLists;
        }
      }
    }
    return 0;
  }

  function getMaxPhoneNumbers() {
    if (activeSubscription.product && products) {
      for (let j = 0; j < products.length; j++) {
        if (activeSubscription.product.path.includes(products[j].id)) {
          return products[j].stripe_metadata_maxPhoneNumbers;
        }
      }
    }
    return 0;
  }

  function convertList(list) {
    const newList = [];
    // Loop through the list and add an object with the age, birthday, first name, last name, and phone number to newList
    try {
      for (let i = 0; i < list.length; i++) {
        const temp = list[i];
  
        const name = temp.name.split(" ");
        var lastName = capitalize(name[0]);
        // Remove comma from lastName
        if (lastName.includes(",")) {
          lastName = lastName.replace(",", "");
        }
        var firstName = capitalize(name[1]);
        var phoneNumber = temp.phone_number.replace(/\D/g,'');
  
        // Remove first number if there are more than 10
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.slice(1);
        }
  
        for (let j = 2; j < name.length; j++) {
          firstName = firstName + " " + capitalize(name[j]);
        }
  
        // Use moment to change the date format to MM-DD-YYYY
        var birthday = moment(temp.birth_date).format('MM-DD-YYYY');
        birthday = new Date(birthday);

        // check if names are undefined. If they are then set them to empty strings
        if (typeof firstName === 'undefined') {
          firstName = "";
        }
        if (typeof lastName === 'undefined') {
          lastName = "";
        }

        // If there is a last name but no first name, then set the first name to the last name
        if (firstName === "" && lastName !== "") {
          firstName = lastName;
          lastName = "";
        }
  
        const tempObj = {
          alert_date: birthday,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber
        }
        newList.push(tempObj);
      }
      return newList;
    } catch (e) {
      console.log("Error converting list");
    }
  }

  function is_numeric(str){
    return /^\d+$/.test(str);
  }

  function getTableDataV2(data) {
    console.log("Getting table data from v2 table");
    var fileData = data;

    const startOfTableRegex = /Name\nGender\nAge\nBirth Date\nAddress\nPhone Number\nE-mail\n/i;
    const endOfTableRegex = /Count: \d+/i;

    try {
      // find the table headers
      var idx = fileData.search(startOfTableRegex);
      // If idx is -1 or fileData is empty, then the table was not found and show an alert
      if (idx === -1 || fileData.length === 0) {
        throw new Error("no_table");
      }

      // Remove the start of the table
      fileData = fileData.substring(idx + 'Name\nGender\nAge\nBirth Date\nAddress\nPhone Number\nE-mail\n'.length + 1);

      // Find the end of the table idx
      idx = fileData.search(endOfTableRegex);
      
      // Remove the end of the table
      return fileData.substring(0, idx);

    } catch (e) {
      console.log("Error getting raw v2 table data");
    }
  }

  function getPeopleV2(data) {
    var lines = data.split('\n');
    var people = [];

    while (lines.length > 0) {
      var line = lines.shift();
      if (line.includes("**")) { // Is bishopric Member
        people.push(new Person(
          line.substring(2), // Name
          lines.shift(), // Gender
          lines.shift(), // Age
          lines.shift(), // Birth Date
          lines.shift(), // Address Line 1
          lines.shift(), // Address Line 2
          "", // Address Line 3 (placeholder)
          lines.shift(), // Phone Number
          lines.shift(), // Email
        ))
      } else { // Is ward member
        people.push(new Person(
          line, // Name
          lines.shift(), // Gender
          lines.shift(), // Age
          lines.shift(), // Birth Date
          lines.shift(), // Address Line 1
          lines.shift(), // Address Line 2
          lines.shift(), // Address Line 3
          lines.shift(), // Phone Number
          lines.shift(), // Email
        ))
      }
      lines.shift(); // Remove the empty line
    }

    console.log("Found", people.length, "people");
    return people;
  }

  function getTableDataV1(data) {
    console.log("Getting table data from v1 table");
    var fileData = data;

    const startOfTableRegex = /All Members/i;
    const endOfTableRegex = /Count: \d+/i;

    try {
      // Find the start of the table
      var idx = fileData.search(startOfTableRegex);
      // If idx is -1, then the table was not found and show an alert
      if (idx === -1) {
        // throw a table not found error
        new Error("no_table");
      }

      // Remove the start of the table
      fileData = fileData.substring(idx + 'All Members'.length + 1);

      // Find the end of the table idx
      idx = fileData.search(endOfTableRegex);
      
      // Remove the end of the table
      return fileData.substring(0, idx);

    } catch (e) {
      console.log(e.message);
      return e;
    }
  }

  const getPeopleV1 = (data) => {
    console.log("Getting people from v1 table");
    try {
      // loop through the file data and create a table
      var tableData = [];
      var lines = data.split('\n');

      var header = lines[0].split('\t');
      // replace all the spaces and punctuation in the header with underscores
      for (var k = 0; k < header.length; k++) {
        header[k] = header[k].replace(/[^a-zA-Z0-9]/g, '_');
        header[k] = header[k].toLowerCase();
      }
      header.shift();
      for (var i = 1; i < lines.length - 1; i++) {
        var line = lines[i];
        var lineData = line.split('\t');
        lineData.shift();

        // Make a new object for each line using the header as the keys
        var member = {};
        for (var j = 0; j < header.length; j++) {
            let key = header[j].toString().toLowerCase();
            var value = lineData[j];
            // remove punctuation from the value except for the @ symbol
            value = value.replace(/[^\w\s@]/gi, '');
            // convert the value to lowercase
            value = value.toLowerCase();
            // remove any leading or trailing whitespace
            value = value.trim();

            // Check if the value is a date matching the format 8 may 1998
            if (value.match(/\d{1,2} \w{3} \d{4}/)) {
                // Convert the date to a date object
                // value = new Date(value);
                // Convert the date to a string in the format YYYY-MM-DD
                value = moment(value).format('D MMM, YYYY');
            }
            member[key] = value;
        }
        // If member is empty then throw an error
        if (Object.keys(member).length === 0 && member.constructor === Object) {
          throw new Error("empty_member");
        }
        let tempPerson = new Person(
          member.name,
          member.gender,
          member.age,
          member.birth_date,
          "",
          "",
          "",
          member.phone_number,
          member.e_mail
        );
        tableData.push(tempPerson);
      }
    } catch (e) {
      console.log("Error parsing v1 table");
      throw e;
    }
    console.log("Found", tableData.length, "people");
    return tableData;
  }


  const buildTablePreview = () => {
    console.log("Creating Table");
    try {
      var tableDataV1 = getTableDataV1(memberList);
      var peopleV1 = getPeopleV1(tableDataV1);
      setParsedMemberList(peopleV1);
    } catch (e) {
      console.log("Error parsing table tried v1 parser")
      try {
        var tableDataV2 = getTableDataV2(memberList);
        var peopleV2 = getPeopleV2(tableDataV2);
        setParsedMemberList(peopleV2);
      } catch (e) {
        console.log("Error parsing table tried v1 & v2 parsers")
        alert("Table not found. Please make sure you are using the member list and not the birthday list.");
      }
    }

  }

  // capitalize the first letter of each word in a string
  const capitalize = (str) => {
    if (typeof str !== 'undefined') {
      return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
    return str;
  }

  const togglePhoneNumberDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setPhoneNumberDrawerState({ ...addRecipientDrawerState, [anchor]: open });
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...addListDrawerState, [anchor]: open });
  };

  // Change both of these functions to default to not allow them to be clicked
  function AddListButton({ anchor }) {
    if (lists) {
      if (lists.length >= getMaxLists()) {
        return (
          <Button disabled variant="contained">Max Lists: {getMaxLists()}</Button>
        )
      }
    }
    return (
      <Button variant="contained" onClick={toggleDrawer(anchor, true)}>Create List <AddIcon /></Button>
    )
  }

  function AddRecipientButton({ anchor }) {
    if (recipients) {
      if (recipients.length >= getMaxPhoneNumbers()) {
        return (
          <Button disabled variant="contained">Max Recipients: {getMaxPhoneNumbers()}</Button>
        )
      }
    }
    return (
      <Button variant="contained" onClick={togglePhoneNumberDrawer(anchor, true)}>Add Recipient <AddIcon /></Button>
    )
  }

  function SetupLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  
    return (
      <Link className={isActive ? 'active' : ''} to={to} {...props}>
        <Typography sx={{ color: 'inherit' }} textAlign="left">{children}</Typography>
      </Link>
    )
  }

  function PricingLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  
    return (
      <Link className={isActive ? 'active' : ''} to={to} {...props}>
        <Typography sx={{ color: 'inherit' }} textAlign="left">{children}</Typography>
      </Link>
    )
  }

  const phoneNumberList = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={10}>
            <Typography variant="h3" color="text.primary" sx={{ ml:1, mt:2 }}>
                Add Recipient
            </Typography>
          </Grid>
          <Grid xs={2} sx={{ mt: 2}}>
            <Button variant="contained" onClick={togglePhoneNumberDrawer(anchor, false)} onKeyDown={togglePhoneNumberDrawer(anchor, false)}>Close <ExpandMoreIcon /></Button>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Stack
        component="form"
        sx={{
          // width: '75ch',
          mt:2, ml:1, mr:1, mb: 2
        }}
        spacing={4}
        noValidate
        autoComplete="off"
      >
      <TextField
        required
        id="recipient-name"
        label="Name"
        defaultValue=""
        variant="filled"
        onChange={(e) => setRecipientName(e.target.value)}
      />

      <TextField
        required
        id="recipient-phone-number"
        label="Phone Number (no punctuation or spaces)"
        defaultValue=""
        variant="filled"
        onChange={(e) => setRecipientPhoneNumber(e.target.value)}
      />

      <TextField
        required
        id="recipient-message"
        label="Message (320 char limit)"
        defaultValue=""
        multiline
        rows={3}
        variant="filled"
        onChange={(e) => setRecipientMessage(e.target.value)}
      />

      <Typography variant="p" color="text.primary" sx={{ ml:1 }}>
          Character count: {recipientMessage.length}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="p" color="text.secondary" sx={{ ml:1, mt: 2}}>
          *insert "first_name" or "last_name" where you would like the persons name to appear in your message. Do NOT include the quotes.
        </Typography>
      </Box>

      <Button variant="contained" onClick={handleAddRecipient} sx={{ mt:2, ml:1, mr:1, mb:2}}>Add<AddIcon /></Button>

      </Stack>
    </Box>
  );

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={10}>
            <Typography variant="h3" color="text.primary" sx={{ ml:1, mt:2 }}>
                Create List
            </Typography>
          </Grid>
          <Grid xs={2} sx={{ mt: 2}}>
            <Button variant="contained" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>Close <ExpandMoreIcon /></Button>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Grid container spacing={1}>
        <Grid xs={3}>
          <Button variant="contained" onClick={() => setNewListType("import_member_list")} sx={{ mt:2, ml:1, mr:1, mb:1}}>Import Member List</Button>
        </Grid>
        {/* <Grid xs={3}>
          <Button variant="contained" onClick={() => setNewListType("basic_list")} sx={{ mt:2, ml:1, mr:1, mb:1}}>Add Members Manually</Button>
        </Grid> */}
      </Grid>

      {newListType === "import_member_list" ? (
        <div>
        <Stack
          component="form"
          sx={{
            // width: '75ch',
            mt:2, ml:1, mr:1, mb: 2
          }}
          spacing={4}
          noValidate
          autoComplete="off"
        >
        <TextField
          required
          id="list-name"
          label="List Name"
          defaultValue=""
          variant="filled"
          onChange={(e) => setListName(e.target.value)}
        />

        <Typography variant="p" color="text.primary" sx={{ ml:1, mt:2 }}>
          Paste Member List Webpage Contents Below
        </Typography>

        <SetupLink to="/setup">*Setup Instructions</SetupLink>

        <TextField
          required
          id="filled-multiline-static-member-list"
          label="Member List"
          multiline
          rows={4}
          defaultValue=""
          variant="filled"
          onChange={(e) => setMemberList(e.target.value)}
        />
        </Stack>

        <Button variant="contained" onClick={buildTablePreview} sx={{ mt:2, ml:1, mr:1, mb:2}}>Preview Table<ChangeCircleIcon /></Button>
        </div>
      ) : (
        <div>
          <Stack
            component="form"
            sx={{
              // width: '75ch',
              mt:2, ml:1, mr:1, mb: 2
            }}
            spacing={4}
            noValidate
            autoComplete="off"
          >

          <TextField
            required
            id="list-name"
            label="List Name"
            defaultValue=""
            variant="filled"
            onChange={(e) => setListName(e.target.value)}
          />

            <Typography variant="p" color="text.primary" sx={{ ml:1, mt:2 }}>
              Add members to the table below
            </Typography>

            <Grid container spacing={2}>
              <Grid xs={2} sx={{ mt: 2}}>
                <TextField
                  required
                  id="first-name"
                  label="First Name"
                  defaultValue=""
                  variant="filled"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid xs={2} sx={{ mt: 2}}>
                <TextField
                  required
                  id="last-name"
                  label="Last Name"
                  defaultValue=""
                  variant="filled"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid xs={3} sx={{ mt: 2}}>
                <MuiTelInput onlyCountries={['US']} value={phoneNumber} onChange={handlePhoneChange} />
              </Grid>
              <Grid xs={2} sx={{ mt: 2}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Birthday"
                    value={birthday}
                    onChange={(newBirthday) => { setBirthday(newBirthday);}}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={2} sx={{ mt: 2}}>
                <Button variant="contained" onClick={addPersonToList}>Add <AddIcon /></Button>
              </Grid>
            </Grid>
          </Stack>
        </div>
      )}

      {parsedMemberList.length > 0 ?
        <div>
          <Button variant="contained" onClick={ async() => { addListDashboard(); }} sx={{ mt:2, ml:1, mr:1, mb:2}}>Create<PlayArrowIcon /></Button>
          <PreviewMemberListTable table={parsedMemberList} deletePersonFromList={deletePersonFromList}/>
          <Button variant="contained" onClick={ async() => { addListDashboard(); }} sx={{ mt:2, ml:1, mr:1, mb:2}}>Create<PlayArrowIcon /></Button>
        </div>
      : <div></div>}
    </Box>
  );

  return (
    <div>
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={8}>
                <Typography variant="h2" color="text.primary" sx={{ mt:2 }}>
                  Dashboard
                </Typography>
              </Grid>
              <Grid xs={2} sx={{ mt: 2}}>
                {['bottom'].map((anchor) => (
                  <Fragment key={anchor}>
                    <AddRecipientButton anchor={anchor}/>
                    <Drawer
                      anchor={anchor}
                      open={addRecipientDrawerState[anchor]}
                      onClose={togglePhoneNumberDrawer(anchor, false)}
                    >
                      {phoneNumberList(anchor)}
                    </Drawer>
                  </Fragment>
                ))}
              </Grid>
              <Grid xs={2} sx={{ mt: 2}}>
                {['bottom'].map((anchor) => (
                  <Fragment key={anchor}>
                    <AddListButton anchor={anchor}/>
                  <Drawer
                    anchor={anchor}
                    open={addListDrawerState[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                  </Fragment>
                ))}
              </Grid>
              {/* <Grid xs={1} sx={{ mt: 2}}>
                <Button variant="contained" onClick={refreshPage}>Refresh <RefreshIcon /></Button>
              </Grid> */}
            </Grid>
          </Box>
        </Container>
        {hasActiveSubscription() ? (
          <div>
            <Card sx={{ ml: 1, mt: 2, mr: 1}}>
              <CardContent>
                <Typography variant="h4" component="div">
                  Text Reminder Recipients
                </Typography>
                <Typography variant="h6" component="div">
                  People who will be notified when it's someones birthday
                </Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow key={"outer"}>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Phone Number</TableCell>
                        <TableCell align="right">Message</TableCell>
                        <TableCell align="right">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    { recipients ? (
                      <TableBody>
                        {recipients.map((recipient) => (
                          <TableRow key={recipient.name} >
                            <TableCell component="th" scope="row">{recipient.name}</TableCell>
                            <TableCell component="th" scope="row" align="right">{formatPhoneNumber(recipient.phoneNumber + "")}</TableCell>
                            <TableCell component="th" scope="row" align="right">{recipient.message}</TableCell>
                            <TableCell align="right"><Button variant="text" onClick={ async () => { await deleteRecipient(recipient);}}><DeleteOutlineIcon/></Button></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (<div></div>)}
                  </Table>
                </TableContainer>
              </CardContent>
              <Box sx={{ flexGrow: 1, mb: 2 }}>
                <Typography variant="p" color="text.secondary" sx={{ ml:1, mt: 2}}>
                  *To update a recipient, delete the old one and add a new one.
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, mb: 2 }}>
                <Typography variant="p" color="text.secondary" sx={{ ml:1, mt: 2}}>
                  **Use the message field to send a custom message to the recipient with the birthday persons name in it for an easy copy and paste
                </Typography>      
              </Box>
            </Card>

            {lists ? <ListsTable lists={ lists } deleteList={deleteList}/> : <div></div>}
          </div>
        ) : (
          <Card sx={{ ml: 1, mt: 2, mr: 1}}>
            <CardContent>
              <Box sx={{ flexGrow: 1, mb: 2 }}>
                <Typography variant="p" color="text.primary" sx={{ mt: 2}}>
                No active subscription. Please visit the pricing page to get started!
                </Typography>
                <PricingLink to="/pricing">Take me there</PricingLink>
              </Box>
            </CardContent>
          </Card>
        )}
    </div>
  );
};
export default Dashboard;