import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import ResponsiveAppBar from './components/ResponsiveAppBar';
import Home from './components/Pages/Home';
import Dashboard from './components/Pages/Dashboard';
// import PageNotFound404 from './components/Pages/PageNotFound404';
import Setup from './components/Pages/Setup';
import Account from './components/Pages/Account';
import Pricing from './components/Pages/Pricing';
import Login from './components/Pages/Login';
import AppInfoDashboard from './components/Pages/AppInfoDashboard';

// MUI
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AppContext from './services/AppContext';
import Container from '@mui/material/Container';


import firebase from './services/firebase';
import { db } from './services/firebase';
import {
  onSnapshot,
  doc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore';

import './App.css';
import UserServicingDashboard from './components/Pages/UserServicingDashboard';

function App() {

  // Variables that the child components need to know about
  const [user, setUser] = useState({}); // They need to know this one but it will be in AppContext

  // Firebase Related Variables
  const [account, setAccount] = useState({});
  const [lists, setLists] = useState([]);

  // Stripe Related Variables
  const [products, setProducts] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState({});
  // const [stripeAccountManagementURL, setStripeAccountManagementURL] = useState("");

  useEffect(() => {
    const initiallizeProducts = async() => {
      // console.log("FIREBASE: Setting up product subscriptions");
        // Active Products (many)
        const productsRef = collection(db, "stripe_products_v1");
        const productsQuery = query(productsRef, where("active", "==", true));
        const productsQuerySnapshot = await getDocs(productsQuery);
        const tempProducts = productsQuerySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id})); 
        
        tempProducts.forEach(async (product) => {
          // Get the prices for each product
          const prices = await getDocs(collection(db, "stripe_products_v1", product.id, "prices"));
          // Add prices list to product
          product.prices = [];
          // Loop through each price
          prices.forEach((price) => {
            // Append each price to the product
            product.prices.push({...price.data(), id: price.id});
          })
          setProducts(tempProducts);
        });
        // console.log("FIREBASE: Done setting up product subscriptions");
    }

    initiallizeProducts();

    // Authenticate the user
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      AppContext.user = user;
    });

    try {
      // These only work if a user is authenticated
      if (user.uid) {
        const userUid = user.uid;

        // useEffect expects things to be synchronyous
        // firestore data fetching is asynchronous
        // so wrap it in an async const
        const initializeSubscribers = async() => {
          // Firestore subscriptions
          // console.log("FIREBASE: Setting up user subscriptions");

          // Account (one & only one)
          const accountRef = doc(db, "accounts_v1", userUid);
          if (accountRef) {
            onSnapshot(accountRef, (doc) => {
              setAccount(doc.data());
            });
          }

          // User Lists (one to many)
          const listsRef = collection(db, "lists_v1");
          const listsQuery = query(listsRef, where("userUid", "==", userUid));
          onSnapshot(listsQuery, (listsQuerySnapshot) => {
            var tempLists = [];
            listsQuerySnapshot.forEach((doc) => {
              var l = doc.data();
              l.id = doc.id;
              tempLists.push(l);
            });
            if (tempLists) {
              setLists(tempLists);
            }
          });

          // User Active Subscriptions (one to one)
          const userSubscriptionsRef = collection(db, "stripe_customers_v1", userUid, "subscriptions");
          const userSubscriptionsQuery = query(userSubscriptionsRef, where("status", "==", "active"));
          const userSubscriptionsQuerySnapshot = await getDocs(userSubscriptionsQuery);
          var tempSubscriptions = [];
          userSubscriptionsQuerySnapshot.forEach((doc) => {
            var s = doc.data();
            s.id = doc.id;
            tempSubscriptions.push(s);
          });
          // They only are allowed 1 subscription
          if (tempSubscriptions.length > 0) {
            
            setActiveSubscription(tempSubscriptions[0]);
          }

          // User Stripe Dashboard Link (one)
          const stripeCustomerRef = doc(db, "stripe_customers_v1", userUid);
          onSnapshot(stripeCustomerRef, (doc) => {
            try {
              const tempStripeLink = doc.data().stripeLink;
              if (tempStripeLink) {
                // setStripeAccountManagementURL(doc.data().stripeLink);
              }
            } catch(e) {
              console.log(e);
            }
          })
          // console.log("FIREBASE: Done setting up user subscriptions");
        }

        initializeSubscribers();

      }
    } catch (e) {
      console.log("Error:");
      console.log(e);
    }
  }, [user]); // everytime user changes state then run useEffect


  // Mutators
  function addListToAccount(listUid) {
    try {
      const addListToAccountDoc = async() => {
        const accountRef = doc(db, "accounts_v1", user.uid);
        await updateDoc(accountRef, { lists: arrayUnion(listUid) });
      }
      addListToAccountDoc();
    } catch(e) {
      console.log("FIREBASE: Error Adding List To Account Lists Array");
      console.log(e);
    }
  }

  function deleteListFromAccount(listUid) {
    try {
      const deleteListFromAccountDoc = async() => {
        const accountRef = doc(db, "accounts_v1", user.uid);
        await updateDoc(accountRef, { lists: arrayRemove(listUid) });
      }
      deleteListFromAccountDoc();
    } catch(e) {
      console.log("FIREBASE: Error Deleting List From Account Lists Array");
      console.log(e);
    }
  }

  function addList(newListObj) {
    try {
      newListObj.userUid = user.uid;

      const addListDoc = async() => {
        const listRef = await addDoc(collection(db, "lists_v1"), newListObj);
        addListToAccount(listRef.id);
      }
      addListDoc();
    } catch (e) {
      console.log("FIREBASE: Error Adding List");
      console.log(e);
    }
  }

  function deleteList(listId) {
    try {
      const deleteListDoc = async() => {
        await deleteDoc(doc(db, "lists_v1", listId));
        deleteListFromAccount(listId);
      }
      deleteListDoc();
    } catch (e) {
      console.log("FIREBASE: Error Deleting List");
      console.log(e);
    }
  }

  function addRecipientToAccount(recipientObj) {
    try {
      const addRecipientToAccountDoc = async() => {
        const accountRef = await doc(db, "accounts_v1", user.uid);
        await updateDoc(accountRef, { recipients: arrayUnion(recipientObj) });
      }
      addRecipientToAccountDoc();
    } catch(e) {
      console.log("FIREBASE: Error Adding Recipient To Account Lists Array");
      console.log(e);
    }
  }

  function deleteRecipientFromAccount(recipientObj) {
    try {
      const deleteRecipientFromAccountDoc = async() => {
        const accountRef = await doc(db, "accounts_v1", user.uid);
        await updateDoc(accountRef, { recipients: arrayRemove(recipientObj) });
      }
      deleteRecipientFromAccountDoc();
    } catch(e) {
      console.log("FIREBASE: Error Deleting Recipient From Account Lists Array");
      console.log(e);
    }
  }

  function getRecipients() {
    try {
      const tempRecipients = account.recipients;
      return tempRecipients;
    } catch (e) {
      console.log(e);
      return []
    }
  }

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://birthdayhero.us/">
          B-Day Hero
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  // if (window.location.host.split('.')[0] === 'church') {
  //   return (
  //     <div className="app">
  //       <AppContext.Provider value={{ user: null, theme: 'light', lists: [] }}>
  //         <ResponsiveAppBar user={user} />
  //         <Routes>
  //           <Route path="/" element={<Home />} />
  //           <Route path="/dashboard" element={<Dashboard />} />
  //           <Route path="/setup" element={<Setup />} />
  //           <Route path="/account" element={<Account user={ user }/>} />
  //           <Route path="/pricing" element={<Pricing user={ user }/>} />
  //         </Routes>
  //       </AppContext.Provider>
  //       <Container
  //         maxWidth="md"
  //         component="footer"
  //         sx={{
  //           borderTop: (theme) => `1px solid ${theme.palette.divider}`,
  //           mt: 8,
  //           py: [3, 6],
  //         }}
  //       >
  //         <Copyright sx={{ mt: 5 }} />
  //       </Container>
  //     </div>
  //   );
  if (false) {
  
  } else {
    return (
      <div className="app">
        <AppContext.Provider value={{ user: null, theme: 'light' }}>
          <ResponsiveAppBar user={user} account={ account }/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/account" element={<Account user={ user }/>} />
            <Route path="/admin/appinfo" element={
              <div>
                {account.userType === "owner" ? (
                    <AppInfoDashboard account={ account }/>
                ) : (<Home />)}
              </div>} />
              <Route path="/admin/userservicing" element={
              <div>
                {account.userType === "owner" ? (
                    <UserServicingDashboard account={ account }/>
                ) : (<Home />)}
              </div>} />
            <Route 
                path="/dashboard"
                element={<Dashboard
                account={account}
                activeSubscription={activeSubscription}
                addList={addList}
                addRecipient={addRecipientToAccount}
                deleteList={deleteList}
                deleteRecipient={deleteRecipientFromAccount}
                lists={lists}
                products={products}
                recipients={getRecipients()}
                />}/>
            <Route path="/login" element={<Login /> } />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/setup" element={<Setup />} />
          </Routes>
        </AppContext.Provider>

        <Container
          maxWidth="md"
          component="footer"
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
          }}
        >
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </div>
    );
  }
}

export default App;