import * as React from 'react';
import { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import AccountCard from '../Cards/AccountCard';
import MessageCard from '../Cards/MessageCard';

import { collection, query, onSnapshot, orderBy, where, limit, startAt } from "firebase/firestore";
import { db } from '../../services/firebase';

const AppInfoDashboard = ({ account }) => {

    const [accounts, setAccounts] = useState([]);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    // Pagination
    const [page, setPage] = useState(null);
    const pageSize = 9;

    function getAccounts() {
        try {
            const getAllAccounts = async() => {
                const accountsRef = collection(db, "accounts_v1");
                const accountsQuery = query(accountsRef, orderBy("dateCreated", "desc"));
                onSnapshot(accountsQuery, (accountsQuerySnapshot) => {
                    var tempAccounts = [];
                    accountsQuerySnapshot.forEach((doc) => {
                        var a = doc.data();
                        a.id = doc.id;
                        tempAccounts.push(a)
                    });
                    if (tempAccounts) {
                        setAccounts(tempAccounts);
                    }
                })
            }
            getAllAccounts();
        } catch(e) {
            console.log("FIREBASE: Error Getting Accounts");
            console.log(e);
        }
    }



    function getTodaysMessages(firstPage = false) {
        setLoading(true);
        try {
            const getMessages = async() => {
                const messagesRef = collection(db, "twilio_messages_v1");
                var startDate = new Date();
                let messagesQuery = query(messagesRef, where("delivery.startTime", "<", startDate), orderBy("delivery.startTime", "desc"), limit(pageSize));

                if (!firstPage && page) {
                    messagesQuery = query(messagesRef, where("delivery.startTime", "<", startDate), orderBy("delivery.startTime", "desc"), limit(pageSize), startAt(page));
                }
                
                onSnapshot(messagesQuery, (messagesQuerySnapshot) => {
                    var tempMessages = [];
                    messagesQuerySnapshot.forEach((doc) => {
                        var a = doc.data();
                        a.id = doc.id;
                        tempMessages.push(a)
                    });
                    if (tempMessages) {
                        setPage(tempMessages[tempMessages.length - 1].delivery.startTime);
                        setMessages(tempMessages);
                    }
                })
            }
            getMessages();
        } catch(e) {
            console.log("FIREBASE: Error Getting Messages");
            console.log(e);
        }
        setLoading(false);
    }

    useEffect(() => {
        getAccounts();
        getTodaysMessages(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="account">
            <Typography variant="h4" color="text.primary" sx={{ mt:2, ml:2 }}>
            Logged in as {account.userType}
            </Typography>
            <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h6" textAlign="left">{account.displayName}</Typography>
            <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h6" textAlign="left">{account.email}</Typography>
            <Divider />

            <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h3" textAlign="left">Accounts: {accounts.length}</Typography>
            <Button variant="text" sx={{ ml: 2}} onClick={ async() => { getAccounts(); }}><ChangeCircleIcon /></Button>
            <Grid container sx={{ m: 2}} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {accounts.map((account) => (
                    <Grid key={account.userUid} xs={4}>
                        <AccountCard account={account}/>
                    </Grid>
                ))}
            </Grid>

            <Divider />
            <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h3" textAlign="left">Messages:</Typography>

            {loading ? (
                
                <Typography sx={{ ml:2, my: 1, color: 'black', display: 'block' }} variant="h3" textAlign="left">Loading...</Typography>
            ) : (
                <Grid container sx={{ m: 2}} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {messages.map((message) => (
                        <Grid xs={4}>
                            <MessageCard message={message}/>
                        </Grid>
                    ))}
                </Grid>
            )
            }
            <Button variant="text" sx={{ ml: 2}} onClick={ async() => { getTodaysMessages(); }}>Next Page<ChangeCircleIcon /></Button>
        </div>
    )
}

export default AppInfoDashboard;