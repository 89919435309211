import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import CardActions from '@mui/material/CardActions';
// import Button from '@mui/material/Button';

import moment from 'moment';

export default function MessageCard({ message }) {
    function formatPhoneNumber(number) {
        if (number) {
            return number.toString().substr(0,3)+"-"+number.toString().substr(3,3); // +"-"+number.toString().substr(6,4);
        }
    }

    function formatTimestamp (timestamp) {
        return moment(timestamp).utc().format('MM/DD/YYYY');
    }
    
    function convertSecondsToTime (seconds) {
        const date = new Date(null);
        date.setSeconds(seconds);
        return date;
    }

    return (
        <Card variant="outlined">
            <React.Fragment>
            <CardContent>
                {/* <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                </Typography> */}
                <Typography variant="h5" component="div">
                {formatPhoneNumber(message.to)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {formatTimestamp(convertSecondsToTime(message.delivery.startTime.seconds))}
                </Typography>
                <Typography variant="body2">
                {message.delivery.state}
                </Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small"></Button>
            </CardActions> */}
            </React.Fragment>
        </Card>
    );
}