import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function MediaCard() {
  return (
    <Card sx={{ maxWidth: 355 }}>
      <CardMedia
        component="img"
        height="350"
        image="\bday-hero-outline.png"
        alt="bday-hero-outline"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        Birthday Hero will save the day by notifying you when it's someones birthday! Now <strong>YOU</strong> are the hero!🎉
        </Typography>
      </CardContent>
    </Card>
  );
}
