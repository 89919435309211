import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// import { CircularProgress } from '@mui/material';

import Row from './ListsTableRow';

export default function ListsTable({ lists, deleteList}) {
  return (
    <Card sx={{ ml:1, mt:2, mr: 1}}>
      <CardContent>
      <Typography variant="h4" component="div">
      My Birthday List
      </Typography>
      <Typography variant="h6" component="div">
      Lists of the people whose birthdays you'd like to remember
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow key={"outer"}>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Date Created (MM/DD/YYYY)</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lists.map((list) => (
              <Row key={list.name} list={list} deleteList={deleteList} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ flexGrow: 1, mt: 2}}>
        <Typography variant="p" color="text.secondary" sx={{ ml:1, mt: 2}}>
          *To update a list, delete the old one and upload a new one.
        </Typography>
      </Box>
    </CardContent>
    </Card>
  );
}
