import * as React from 'react';
import { useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

import { auth } from '../services/firebase';
import { signInWithGoogle } from '../services/firebase';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import AppContext from '../services/AppContext';

import firebase from '../services/firebase';

function LogoutLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      AppContext.user = user;
    })
  }, []);
  
  return (
    <Link className={isActive ? 'active' : ''} to={to} {...props}>
      <Typography sx={{ my: 1, color: 'black', display: 'block' }} textAlign="center" onClick={() => auth.signOut()}>{children}</Typography>
    </Link>
  )
}

function AccountLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link className={isActive ? 'active' : ''} to={to} {...props}>
      <Typography sx={{ my: 1, color: 'black', display: 'block' }} textAlign="center">{children}</Typography>
    </Link>
  )
}

function NavbarLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link className={isActive ? 'active' : ''} to={to} {...props}>
      <Typography sx={{ my: 2, color: 'white', display: 'block' }} textAlign="center">{children}</Typography>
    </Link>
  )
}

function CollapsedNavbarLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link className={isActive ? 'active' : ''} to={to} {...props}>
      <Typography sx={{ my: 1, color: 'primary', display: 'block' }} textAlign="center">{children}</Typography>
    </Link>
  )
}

function NavbarLogoLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link className={isActive ? 'active' : ''} to={to} {...props}>
      <Typography
        variant="h6"
        noWrap
        sx={{
          mr: 2,
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'white',
          textDecoration: 'none',
        }}
      >
        {children}
      </Typography>
    </Link>
  )
}

function CollapsableNavbarLogoLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link className={isActive ? 'active' : ''} to={to} {...props}>
      <Typography
        variant="h5"
        noWrap
        component="a"
        href=""
        sx={{
          mr: 2,
          display: { xs: 'flex', md: 'none' },
          flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'white',
          textDecoration: 'none',
        }}
      >
        {children}
      </Typography>
    </Link>
  )
}

const ResponsiveAppBar = ({ user, account }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <NavbarLogoLink to="/">🎈B-Day Hero🦸</NavbarLogoLink>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem key="pricing" onClick={handleCloseNavMenu}>
              <CollapsedNavbarLink to="/pricing">Pricing</CollapsedNavbarLink>
            </MenuItem>

            <MenuItem key="setup" onClick={handleCloseNavMenu}>
              <CollapsedNavbarLink to="/setup">Setup</CollapsedNavbarLink>
            </MenuItem>

            {user ? (            
            <MenuItem key="dashboard" onClick={handleCloseNavMenu}>
              <CollapsedNavbarLink to="/dashboard">Dashboard</CollapsedNavbarLink>
            </MenuItem>) : (<div></div>)}

            {account.userType === "owner" ? (
              <div>
                {user ? (            
                  <MenuItem key="admin-info-dashboard" onClick={handleCloseNavMenu}>
                    <CollapsedNavbarLink to="/admin/appinfo">Info</CollapsedNavbarLink>
                  </MenuItem>) : (<div></div>)
                }
                {user ? (            
                  <MenuItem key="admin-user-servicing-dashboard" onClick={handleCloseNavMenu}>
                    <CollapsedNavbarLink to="/admin/userservicing">Acount Servicing</CollapsedNavbarLink>
                  </MenuItem>) : (<div></div>)
                }
              </div>
            ) : (<div></div>)}
          </Menu>
        </Box>
        <CollapsableNavbarLogoLink to="/">🎈B-Day Hero🦸</CollapsableNavbarLogoLink>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <MenuItem key="pricing" onClick={handleCloseNavMenu}>
            <NavbarLink to="/pricing">Pricing</NavbarLink>
          </MenuItem>

          <MenuItem key="setup" onClick={handleCloseNavMenu}>
            <NavbarLink to="/setup">Setup</NavbarLink>
          </MenuItem>

          {user ? (            
            <MenuItem key="dashboard" onClick={handleCloseNavMenu}>
              <NavbarLink to="/dashboard">Dashboard</NavbarLink>
            </MenuItem>) : (<div></div>)}

          {account.userType === "owner" ? (
            <div>
              {user ? (            
                <MenuItem key="admin-info-dashboard" onClick={handleCloseNavMenu}>
                  <NavbarLink to="/admin/appinfo">Info</NavbarLink>
                </MenuItem>) : (<div></div>)
              }
            </div>
          ) : (<div></div>)}

          {account.userType === "owner" ? (
            <div>
              {user ? (            
                <MenuItem key="admin-user-servicingdashboard" onClick={handleCloseNavMenu}>
                  <NavbarLink to="/admin/userservicing">Account Servicing</NavbarLink>
                </MenuItem>) : (<div></div>)
              }
            </div>
          ) : (<div></div>)}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Account">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {
                user ? (
                <Avatar alt={user.displayName} src={user.photoURL} />
                ) : (
                <MenuItem key="Login" onClick={signInWithGoogle}>
                  <Typography textAlign="center" sx={{ my: 2, color: 'white', display: 'block' }}><i className="fab fa-google"></i>Login</Typography>
                </MenuItem>)
              }
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {
              user ? (
                <div>
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={user.displayName} src={user.photoURL} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {/* <MenuItem key={user.displayName}>
                          <Typography sx={{ my: 1, color: 'black', display: 'block' }} textAlign="center">{user.displayName}</Typography>
                      </MenuItem>
                      <MenuItem key={user.email}>
                          <Typography sx={{ my: 1, color: 'black', display: 'block' }} textAlign="center">{user.email}</Typography>
                      </MenuItem> */}
                      <MenuItem key="account">
                          <AccountLink to="/account">Account</AccountLink>
                      </MenuItem>
                      <MenuItem key="logout" onClick={handleCloseUserMenu}>
                          <LogoutLink to="/">Logout</LogoutLink>
                      </MenuItem>
                    </Menu>
                  </Box>
                </div>
              ) : ( <div> </div> )
            }
          </Menu>
        </Box>
      </Toolbar>
    </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
