import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import CardActions from '@mui/material/CardActions';
// import Button from '@mui/material/Button';

export default function AccountCard({ account }) {

    return (
        <Card variant="outlined">
            <React.Fragment>
            <CardContent>
                {/* <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                </Typography> */}
                <Typography variant="h5" component="div">
                {account.displayName}
                </Typography>
                {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                { account.email }
                </Typography> */}
                <Typography variant="body2">
                Recipients: {account.recipients.length} Lists: {account.lists.length}
                </Typography>
                <Typography variant="body2">
                Total Messages Sent: {account.totalMessagesSent}
                </Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small"></Button>
            </CardActions> */}
            </React.Fragment>
        </Card>
    );
}