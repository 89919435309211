import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import { CardActionArea } from '@mui/material';

export default function HowItWorksCard() {
  return (
    <Card sx={{ maxWidth: 355 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="100"
          image="\example-text-willey-coyote.jpg"
          alt="example-text-willey-coyote"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          💡How it works
          </Typography>
          
          <List>
            <ListItem>
              <Typography variant="body2" color="text.secondary">
              On their birthday you will receive a text including their:
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Name
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Phone #
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Your custom message to send them!
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Then YOU copy the custom message
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Click the included phone number
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Paste the message
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              Send the message and become the Birthday Hero!
              </Typography>
            </ListItem>

            <Divider />

            <ListItem>
              <Typography variant="body2" color="text.secondary">
              It only takes 5 seconds!
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
